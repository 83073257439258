import Cookies from "js-cookie";
import store from "@/store";

/**
 * 权限
 * @param {*} key
 */
export function hasPermission(key) {
  return window.SITE_CONFIG["permissions"].indexOf(key) !== -1 || false;
}

/**
 * 获取字典数据列表
 * @param dictType  字典类型
 */
export function getDictDataList(dictType) {
  const type = window.SITE_CONFIG["dictList"].find(
    (element) => element.dictType === dictType
  );
  if (type) {
    return type.dataList;
  } else {
    return [];
  }
}

/**
 * 获取字典名称
 * @param dictType  字典类型
 * @param dictValue  字典值
 */
export function getDictLabel(dictType, dictValue) {
  const type = window.SITE_CONFIG["dictList"].find(
    (element) => element.dictType === dictType
  );
  if (type) {
    const val = type.dataList.find(
      (element) => element.dictValue == dictValue + ""
    );
    if (val) {
      return val.dictLabel;
    } else {
      return dictValue;
    }
  } else {
    return dictValue;
  }
}

/**
 * 清除登录信息
 */
export function clearLoginInfo() {
  store.commit("resetStore");
  Cookies.remove("token");
  window.SITE_CONFIG["dynamicMenuRoutesHasAdded"] = false;
}

/**
 * 获取uuid
 */
export function getUUID() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    return (c === "x" ? (Math.random() * 16) | 0 : "r&0x3" | "0x8").toString(
      16
    );
  });
}

/**
 * 获取svg图标(id)列表
 */
export function getIconList() {
  var res = [];
  var list = document.querySelectorAll("svg symbol");
  for (var i = 0; i < list.length; i++) {
    res.push(list[i].id);
  }

  return res;
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate(data, id = "id", pid = "pid") {
  var res = [];
  var temp = {};
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i];
  }
  for (var k = 0; k < data.length; k++) {
    if (!temp[data[k][pid]] || data[k][id] === data[k][pid]) {
      res.push(data[k]);
      continue;
    }
    if (!temp[data[k][pid]]["children"]) {
      temp[data[k][pid]]["children"] = [];
    }
    temp[data[k][pid]]["children"].push(data[k]);
    data[k]["_level"] = (temp[data[k][pid]]._level || 0) + 1;
  }
  return res;
}
/**
 * 格式化数字 千分位,2位小数
 * @param {} num
 * @returns
 */
export function format(num) {
  if (num == 0) {
    return num;
  }
  let res = Number(num)?.toFixed(2);
  res = Number(res)?.toLocaleString();
  return res;
}

/**
 ** 加法函数，用来得到精确的加法结果
 ** 说明：javascript的加法结果会有误差，在两个浮点数相加的时候会比较明显。这个函数返回较为精确的加法结果。
 ** 调用：accAdd(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accAdd(arg1, arg2) {
  var r1, r2, m, c;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  c = Math.abs(r1 - r2);
  m = Math.pow(10, Math.max(r1, r2));
  if (c > 0) {
    var cm = Math.pow(10, c);
    if (r1 > r2) {
      arg1 = Number(arg1.toString().replace(".", ""));
      arg2 = Number(arg2.toString().replace(".", "")) * cm;
    } else {
      arg1 = Number(arg1.toString().replace(".", "")) * cm;
      arg2 = Number(arg2.toString().replace(".", ""));
    }
  } else {
    arg1 = Number(arg1.toString().replace(".", ""));
    arg2 = Number(arg2.toString().replace(".", ""));
  }
  return (arg1 + arg2) / m;
}

/**
 ** 减法函数，用来得到精确的减法结果
 ** 说明：javascript的减法结果会有误差，在两个浮点数相减的时候会比较明显。这个函数返回较为精确的减法结果。
 ** 调用：accSub(arg1,arg2)
 ** 返回值：arg1加上arg2的精确结果
 **/
export function accSub(arg1, arg2) {
  var r1, r2, m, n;
  try {
    r1 = arg1.toString().split(".")[1].length;
  } catch (e) {
    r1 = 0;
  }
  try {
    r2 = arg2.toString().split(".")[1].length;
  } catch (e) {
    r2 = 0;
  }
  m = Math.pow(10, Math.max(r1, r2)); //last modify by deeka //动态控制精度长度
  n = r1 >= r2 ? r1 : r2;
  return ((arg1 * m - arg2 * m) / m).toFixed(n);
}

/**
 ** 乘法函数，用来得到精确的乘法结果
 ** 说明：javascript的乘法结果会有误差，在两个浮点数相乘的时候会比较明显。这个函数返回较为精确的乘法结果。
 ** 调用：accMul(arg1,arg2)
 ** 返回值：arg1乘以 arg2的精确结果
 **/
export function accMul(arg1, arg2) {
  var m = 0,
    s1 = arg1.toString(),
    s2 = arg2.toString();
  try {
    m += s1.split(".")[1].length;
  } catch (e) {}
  try {
    m += s2.split(".")[1].length;
  } catch (e) {}
  return (
    (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
    Math.pow(10, m)
  );
}

/**
 ** 除法函数，用来得到精确的除法结果
 ** 说明：javascript的除法结果会有误差，在两个浮点数相除的时候会比较明显。这个函数返回较为精确的除法结果。
 ** 调用：accDiv(arg1,arg2)
 ** 返回值：arg1除以arg2的精确结果
 **/
export function accDiv(arg1, arg2) {
  var t1 = 0,
    t2 = 0,
    r1,
    r2;
  try {
    t1 = arg1.toString().split(".")[1].length;
  } catch (e) {}
  try {
    t2 = arg2.toString().split(".")[1].length;
  } catch (e) {}
  if (Math) {
    r1 = Number(arg1.toString().replace(".", ""));
    r2 = Number(arg2.toString().replace(".", ""));
    return (r1 / r2) * pow(10, t2 - t1);
  }
}

// countDownTime 秒的单位
export function getDayMinSeconds(countDownTime,type='string',lang) {
  const tempDay = Math.floor(countDownTime / (60 * 60 * 24));
  const tempHour = Math.floor(countDownTime / (60 * 60)) - tempDay * 24;
  const tempMin = Math.floor(countDownTime / 60) - tempDay * 24 * 60 - tempHour * 60;
  const tempSecond = countDownTime - tempDay * 24 * 60 * 60 - tempHour * 60 * 60 - tempMin * 60;
  if(type=='string'){
    if(lang=='en'){
        return `${tempDay} day ${tempHour} hour ${tempMin} min`;
    }
    if(lang=='zh'){
      return `${tempDay} 天 ${tempHour} 小时 ${tempMin} 分钟`;
    }
  }else{
      return [tempDay,tempHour,tempMin,tempSecond,countDownTime];
  }
}

export function getDataTime(seconds){
  let date = new Date(seconds * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
  let Y = date.getFullYear();
  let M = date.getMonth()+1 ;
  M = M.toString().padStart(2, '0');
  let D = date.getDate();
  D = D.toString().padStart(2, '0');
  let h = date.getHours();
  h = h.toString().padStart(2, '0');
  let m = date.getMinutes();
  m = m.toString().padStart(2, '0');
  let s = date.getSeconds();
  s = s.toString().padStart(2, '0');
  return `${Y}-${M}-${D} ${h}:${m}:${s}`;
}

export function removeEmptyValues(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === undefined || obj[key] === "") {
      delete obj[key];
    } else if (typeof obj[key] === 'object' && Object.keys(obj[key]).length === 0) {
      delete obj[key];
    }
  }
  return obj;
}

/**
 * 获取当年月日
 */
export function getCurrentDate() {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}