window.SITE_CONFIG = {};
window.SITE_CONFIG["version"] = "v5.0.0";
window.SITE_CONFIG["nodeEnv"] = "<%= process.env.VUE_APP_MODE %>";
window.SITE_CONFIG["storeState"] = {};
window.SITE_CONFIG["contentTabDefault"] = {
  name: "order",
  params: {},
  query: {},
  menuId: "1592814776144896002",
  title: "",
  isTab: true,
  iframeURL: "",
};
window.SITE_CONFIG["menuList"] = [];
window.SITE_CONFIG["permissions"] = [];
window.SITE_CONFIG["dynamicRoutes"] = [];
window.SITE_CONFIG["dynamicMenuRoutes"] = [];
window.SITE_CONFIG["dynamicMenuRoutesHasAdded"] = false;
window.SITE_CONFIG["apiURL"] = process.env.VUE_APP_BASE_URL;
